<template>
  <div>
    <section class="page-title text-center">
      <div class="container">
        <div class="content-box">
          <h1>{{ $t("Appointment") }} {{ $getByLang(name) }}</h1>
        </div>
      </div>
    </section>

    <!--SECTION START-->
    <section style="background-color: white; padding-top: 36px; padding-bottom: 150px">
      <div class="mr-3 ml-3">
        <b-tabs content-class="mt-3" v-model="tabIndex" align="center">
          <b-tab :title="$t('Calendar')" active>
            <div class="row">
              <div class="col-md-3">
                <b-calendar v-model="day" start-weekday="1" block></b-calendar>
                <br />
                <b-list-group class="no-in-phone">
                  <b-list-group-item v-for="option in userList" :key="option.id" :value="option.id">
                    <router-link :to="'/user/' + option.id" class="asa">
                      <img style="height: 50px; width: 50px; padding-bottom: 5px" :src="$baseUploadURL + option.image"
                        alt="" />
                      {{ option.fullName }}
                    </router-link>
                  </b-list-group-item>
                </b-list-group>
              </div>
              <div class="col-md-9">

                <div style="text-align: center;" v-if="!loading">

                  <img src="../../../assets/l.gif" alt="" style="border-radius: 20px;">
                </div>
                <component v-show="loading" :is="fullCalendarComponent" v-if="isFullCalendarLoaded"
                  :options="calendarOptions" ref="calendar2" />
              </div>
              <div class="col-md-12">
                <b-button block variant="danger" :disabled="bodyArray.length == 0" @click="tabIndex++"
                  style="background-color: #f26838; color: white">Nächste</b-button>
              </div>
            </div>
          </b-tab>
          <b-tab :title="$t('Appointment') + ' ' + $t('info')" :disabled="bodyArray.length == 0">
            <div class="wwwppp">
              <form method="post" class="row">
                <div class="col-md-2">
                  <div class="mb-3">
                    <label for="sexType" class="form-label">
                      {{ $t("Type") }}
                    </label>

                    <b-form-select v-model="body.sexType" class="mb-3">
                      <b-form-select-option value="MR">{{
                        $t("MR")
                        }}</b-form-select-option>
                      <b-form-select-option value="MRS">{{
                        $t("MRS")
                        }}</b-form-select-option>
                    </b-form-select>
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="mb-3">
                    <label for="fname" class="form-label">
                      {{ $t("First Name") }}</label>
                    <input type="text" class="form-control" id="fname" v-model="body.fname" />
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="mb-3">
                    <label for="lname" class="form-label">{{
                      $t("Last Name")
                      }}</label>
                    <input type="text" class="form-control" id="lname" v-model="body.lname" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="mb-3">
                    <label for="phone" class="form-label">{{
                      $t("Phone")
                      }}</label>

                    <vue-tel-input v-model.trim="body.phone" v-bind="{
                      mode: 'international',
                    }" :allCountries="allCountries"></vue-tel-input>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="mb-3">
                    <label for="paymentMethod">{{ $t("Pay Type") }}</label>
                    <select class="custom-select" id="paymentMethod" name="paymentMethod" v-model="body.paymentMethod">
                      <option value="cash">{{ $t("Vor dem Ort") }}</option>
                      <option value="online">{{ $t("Online") }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-12" v-if="body.baytype != 'Cash2'">
                  <div class="mb-3">
                    <label for="email" class="form-label">{{
                      $t("Email address")
                      }}</label>
                    <input type="email" class="form-control" id="email" v-model="body.email"
                      placeholder="name@example.com" />
                  </div>
                </div>
                <!-- <div class="col-md-6">
                  
                </div> -->

                <div class="col-md-12" v-if="body.baytype == 'Cash2'">
                  <div class="mb-3">
                    <label for="hotel" class="form-label" v-if="body.baytype == 'Cash2'">
                      {{ $t("hotel guest") }}
                    </label>
                    <input type="number" class="form-control" id="hotel" v-model.number="body.hotel" />
                  </div>
                </div>
                <div class="col-md-12" v-if="body.baytype == 'Gift'">
                  <div class="mb-3">
                    <label for="gift_id" class="form-label">{{
                      $t("Gift Card Number")
                      }}</label>
                    <input type="number" class="form-control" id="gift_id" v-model.number="body.gift_id" />
                  </div>
                </div>
                <div class="col-md-12" v-if="body.baytype == 'Package'">
                  <div class="mb-3">
                    <label for="gift_id" class="form-label">{{
                      $t("Sessions Number")
                      }}</label>
                    <input type="text" class="form-control" id="package_id" v-model="body.package_id" />
                  </div>
                </div>
                <!-- <div class="col-md-10" v-if="body.baytype == 'Online'">
                  <div class="mb-3">
                    <label for="address" class="form-label">
                      {{ $t("AddressHome") }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="address"
                      v-model="body.address"
                    />
                  </div>
                </div>
                <div class="col-md-2" v-if="body.baytype == 'Online'">
                  <div class="mb-3">
                    <label for="zip" class="form-label">
                      {{ $t("zip") }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="zip"
                      v-model="body.zip"
                    />
                  </div>
                </div>

                <div class="col-md-6" v-if="body.baytype == 'Online'">
                  <div class="mb-3">
                    <label for="city" class="form-label">
                      {{ $t("city") }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="city"
                      v-model="body.city"
                    />
                  </div>
                </div>
                <div class="col-md-6" v-if="body.baytype == 'Online'">
                  <div class="mb-3">
                    <label for="country" class="form-label">
                      {{ $t("country") }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="country"
                      v-model="body.country"
                    />
                  </div>
                </div> -->
                <div class="col-md-12">
                  <div class="mb-3">
                    <label for="note" class="form-label">{{
                      $t("note")
                      }}</label>
                    <textarea class="form-control" v-model="body.note" id="note" rows="3"></textarea>
                  </div>
                  <b-button block variant="danger" :disabled="!this.body.fname &&
                    !this.body.lname &&
                    !this.body.phone &&
                    // !this.body.email &&
                    !this.body.date &&
                    !this.body.time
                    " @click="save" style="background-color: #f26838; color: white">{{ $t("Confirm") }}</b-button>
                </div>
              </form>
            </div>
          </b-tab>
        </b-tabs>

        <b-modal v-model="modalShow" id="modal-prevent-closing">
          <div class="mb-3">
            <label for="personsNumber" class="form-label"> Massagetyp</label>

            <CascadeSelect class="mb-3" v-model="newAdd.massageId" :options="newMassageList" optionLabel="name"
              optionValue="id" optionGroupLabel="name" :optionGroupChildren="['list']" style="width: 100%">
              <template #option="slotProps">
                <div class="country-item">
                  <img :src="$baseUploadURL + slotProps.option.image" v-if="slotProps.option.image" style="
                      width: 40px;
                      height: 30px;
                      border-radius: 5px;
                      margin-right: 14px;
                    " />

                  <span>{{ slotProps.option.name }}</span>
                </div>
              </template>
            </CascadeSelect>
          </div>

          <div class="mb-3">
            <label for="personsNumber" class="form-label">
              Preisplan
              <span style="color: red"> pro Person </span>
            </label>
            <b-form-select v-model="newAdd.pricesId" class="mb-3">
              <b-form-select-option v-for="item of pricesList" :key="item.id" :value="item.id">
                {{ $getByLang(item.name) }}

                {{ item.minutes }} Minuten =
                {{ item.price }}€</b-form-select-option>
            </b-form-select>
          </div>

          <template #modal-footer>
            <div class="w-100">
              <p class="float-left" style="font-size: 11px">
                Bitte lassen Sie keine Lücken von weniger als einer Stunde.
              </p>
              <b-button variant="primary" size="sm" class="float-right" @click="nextAddToList" style="margin: 0.25rem">
                Nächste
              </b-button>
              <!-- <b-button
                variant="secondary"
                size="sm"
                class="float-right"
                @click="addNewObject"
                style="margin: 0.25rem"
              >
                Noch eine Reservierung
              </b-button> -->
            </div>
          </template>
        </b-modal>
      </div>
    </section>
  </div>
</template>

<script>
import "@fullcalendar/core/vdom"; // solves problem with Vite

import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import moment from "moment";

export default {
  components: {

  },
  data() {
    return {

      minDate: new Date(),
      tabIndex: 0,
      modalShow: false,
      allCountries: [
        ["Austria (Österreich)", "at", "43"],
        ["Germany (Deutschland)", "de", "49"],
        ["Switzerland (Schweiz)", "ch", "41"],
        ["Liechtenstein", "li", "423"],
      ].map(([name, iso2, dialCode]) => ({
        name,
        iso2: iso2.toUpperCase(),
        dialCode,
      })),
      calendarOptions: {
        validRange: {
          start: new Date(), // Locks old dates
        },
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
          resourceTimeGridPlugin,
        ],
        headerToolbar: {
          left: "prev,next",
          center: "title",
          right: "today",
        },
        allDaySlot: false, // Hide all-day row

        initialView: "resourceTimeGridDay",
        initialDate: new Date(),
        slotDuration: "00:15:00",
        slotLabelInterval: 15,
        slotMinutes: 15,
        slotLabelFormat: { hour: "numeric", minute: "2-digit", hour12: false },

        businessHours: true,
        slotMinTime: "09:00",
        slotMaxTime: "22:00",
        selectMirror: true,

        events: [],
        schedulerLicenseKey: "CC-Attribution-NonCommercial-NoDerivatives",
        resources: [],
        selectable: true,
        select: this.handleDateSelect,
        selectLongPressDelay: 150,
        eventClick: this.handleEventClick,
        resourceLabelContent: (arg) => {
          let image = document.createElement('img');
          image.src = arg.resource.extendedProps.image;
          image.style.width = '50px'; // Set the width of the image
          image.style.height = '50px'; // Set the height of the image
          image.style.marginRight = '10px'


          let label = document.createElement('div');
          label.style.fontWeight = '700';
          label.appendChild(image);
          label.appendChild(document.createTextNode(arg.resource.title));

          return { html: label.outerHTML };
        }
      },
      body: {
        usersId: null,
        email: null,
        phone: null,
        sexType: null,
        fname: null,
        lname: null,
        address: null,
        zip: null,
        city: null,
        country: null,
        paymentMethod: "cash",
      },
      newAdd: {
        adminsId: null,
        massageId: null,
        pricesId: null,
      },
      bodyArray: [],
      userList: [],
      massageList: [],

      newMassageList: [],

      day: null,
      name: null,
      pricesList: [],
      appointmentsList: [],
      id: null,
      stopList: [],
      loading: true,
    };
  },

  methods: {
    retObjVal(user, date) {
      let lock = false;

      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const dddd123 = new Date(date);
      const d = new Date(dddd123);
      const dayName = days[d.getDay()];

      if (user[dayName] == false) {
        lock = true;
      }

      if (user.hold && user.holdFrom) {
        var d1 = new Date(dddd123);
        var d2 = new Date(user.hold);
        var d3 = new Date(user.holdFrom);
        if (d1.getTime() < d2.getTime() && d1.getTime() > d3.getTime()) {
          lock = true;
        }
      }

      return lock;
    },
    getData() {
      this.loading = false;
      this.calendarOptions.resources = [];
      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const d = new Date(this.day);
      const dayName = days[d.getDay()];

      for (const item of this.userList) {
        if (this.retObjVal(item, d) == false) {
          this.calendarOptions.resources.push({
            id: item.id,
            title: item.fullName,

            image: this.$baseUploadURL + item.image,
            businessHours: {
              startTime: item[dayName + "Start"], // a start time (10am in this example)
              endTime: item[dayName + "End"], // an end time (6pm in this example)
              daysOfWeek: [0, 1, 2, 3, 4, 5, 6], // Mon,Wed,Fri
            },
          });
        }
      }

      this.$http
        .post(`appointments/gatByDay?isC=0&date=${this.day}`)
        .then(async (res) => {
          this.calendarOptions.events = [];
          const list = res.data.data;
          this.appointmentsList = list;
          for (const item of list) {
            if (item.id != this.id) {
              const x = this.calendarOptions.events.push({
                id: item.id,
                title: new Date(item.start),
                start: new Date(item.start),
                end: new Date(item.end),
                resourceId: Number(item.adminsId?.id),
                allDay: false,
                backgroundColor: item.massageId?.color,
              });
            }
          }

          const stopes = await this.$http.get(`stoptimes?date=${this.day}`);
          if (stopes && stopes.data && stopes.data.data) {
            const stopList = stopes.data.data;
            this.stopList = stopes.data.data;
            for (const item of stopList) {
              this.calendarOptions.events.push({
                id: "stop_" + item.id,
                title: "Stop Time",
                start: new Date(item.start),
                end: new Date(item.end),
                resourceId: Number(item.adminsId?.id),
                allDay: false,
                backgroundColor: "#000",
              });
            }
          }
          this.loading = true;

          let calendarApi = this.$refs.calendar2.getApi();
          calendarApi.changeView("resourceTimeGridDay", d);
        });
    },
    handleEventClick(clickInfo) {
      if (!clickInfo.event.id) {
        if (
          confirm(
            `Are you sure you want to delete the event '${clickInfo.event.title}'`
          )
        ) {
          // this.body.time = null;
        }
      }
    },
    checkIfTimeAvailable(start, userId) {
      let lock = false;

      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const d = new Date(start);
      const dayName = days[d.getDay()];
      const user = this.userList.find((u) => u.id == userId);

      const day = moment(start).format("YYYY-MM-DD");

      const startInDay = moment(
        day + " " + user[dayName + "Start"],
        "YYYY-MM-DD HH:mm"
      ).format();

      const endInDay = moment(
        day + " " + user[dayName + "End"],
        "YYYY-MM-DD HH:mm"
      ).format();
      if (startInDay && endInDay) {
        const d1 = new Date(d);
        const d2 = new Date(startInDay);
        const d3 = new Date(endInDay);

        if (d1.getTime() >= d2.getTime() && d1.getTime() <= d3.getTime()) {
          lock = false;
        } else {
          lock = true;
        }
      }

      for (const item of this.bodyArray) {
        if (item.adminsId == userId) {
          const d1 = new Date(d);
          const d2 = new Date(item.start);
          const d3 = new Date(item.end);

          if (d1.getTime() >= d2.getTime() && d1.getTime() < d3.getTime()) {
            lock = true;
          }
        }
      };


      for (const item of this.appointmentsList) {
        if (
          startInDay &&
          endInDay &&
          item.adminsId &&
          item.adminsId.id == userId &&
          item.id != this.id
        ) {
          const d1 = new Date(d);
          const d2 = new Date(item.start);
          const d3 = new Date(item.end);

          if (d1.getTime() >= d2.getTime() && d1.getTime() < d3.getTime()) {
            lock = true;
            console.log("M", item.id);
          }
        }
      }

      for (const item of this.stopList) {
        if (
          startInDay &&
          endInDay &&
          item.adminsId &&
          item.adminsId.id == userId
        ) {
          const d1 = new Date(d);
          const d2 = new Date(item.start);
          const d3 = new Date(item.end);

          if (d1.getTime() >= d2.getTime() && d1.getTime() < d3.getTime()) {
            lock = true;
          }
        }
      }

      if (lock) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: user.fullName + " is not available",
          life: 3000,
        });
      }

      return lock;
    },
    checkIfTimeAvailableEnd(end, userId) {
      let lock = false;

      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const d = new Date(end);
      const dayName = days[d.getDay()];
      const user = this.userList.find((u) => u.id == userId);

      const day = moment(end).format("YYYY-MM-DD");

      const startInDay = moment(
        day + " " + user[dayName + "Start"],
        "YYYY-MM-DD HH:mm"
      ).format();

      const endInDay = moment(
        day + " " + user[dayName + "End"],
        "YYYY-MM-DD HH:mm"
      ).format();


      for (const item of this.bodyArray) {
        if (item.adminsId == userId) {
          const d1 = new Date(d);
          const d2 = new Date(item.start);
          const d3 = new Date(item.end);

          if (d1.getTime() > d2.getTime() && d1.getTime() <= d3.getTime()) {
            lock = true;
          }
        }
      };


      for (const item of this.appointmentsList) {
        if (
          startInDay &&
          endInDay &&
          item.adminsId &&
          item.adminsId.id == userId &&
          item.id != this.id
        ) {
          const d1 = new Date(d);
          const d2 = new Date(item.start);
          const d3 = new Date(item.end);

          if (d1.getTime() > d2.getTime() && d1.getTime() <= d3.getTime()) {
            lock = true;
          }
        }
      }

      for (const item of this.stopList) {
        if (
          startInDay &&
          endInDay &&
          item.adminsId &&
          item.adminsId.id == userId
        ) {
          const d1 = new Date(d);
          const d2 = new Date(item.start);
          const d3 = new Date(item.end);

          if (d1.getTime() > d2.getTime() && d1.getTime() <= d3.getTime()) {
            lock = true;
          }
        }
      }

      if (lock) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: user.fullName + " is not available",
          life: 3000,
        });
      }
      return lock;
    },
    isDateTimeInPast(dateTime) {
      const inputDateTime = new Date(dateTime);
      const currentDateTime = new Date();

      return inputDateTime < currentDateTime;
    },
    handleDateSelect(selectInfo) {
      this.newAdd.adminsId = selectInfo.resource.id;
      if (
        this.checkIfTimeAvailable(selectInfo.startStr, selectInfo.resource.id)
      ) {
        return;
      }

      if (this.isDateTimeInPast(selectInfo.startStr)) {
        return;
      }

      this.newAdd.start = moment(selectInfo.startStr).format();
      this.modalShow = true;
      this.$http.get(`admins/getMassage/${selectInfo.resource.id}`).then(
        (res) => {
          if (res.data) {
            this.massageList = res.data;

            const newMassageList = [];
            for (const item of this.massageList) {
              if (
                item.sectionsId &&
                newMassageList.find(
                  (el) => Number(el.id) == Number(item.sectionsId.id)
                )
              ) {
                const index = newMassageList.findIndex(
                  (el) => Number(el.id) == Number(item.sectionsId.id)
                );
                newMassageList[index].list.push({
                  ...item,
                  name: this.$getByLang(item.name),
                });
              } else {
                newMassageList.push({
                  ...item.sectionsId,
                  name: this.$getByLang(item.sectionsId.name),
                  list: [{ ...item, name: this.$getByLang(item.name) }],
                });
              }
            }

            this.newMassageList = newMassageList;

            if (this.massageList.length > 0) {
              this.newAdd.massageId =
                this.newAdd.massageId == null
                  ? this.massageList[0].id
                  : this.newAdd.massageId;

              this.pricesList = this.massageList[0].pricesList;
              this.newAdd.pricesId =
                this.newAdd.pricesId == null
                  ? this.pricesList[0].id
                  : this.newAdd.pricesId;
            }
          }
        },
        (err) => {
          console.log(err);
        }
      );
    },
    nextAddToList() {
      this.addNewObject();

      setTimeout(() => {
        this.tabIndex++;
      }, 1500);
    },
    addNewObject() {
      const price = this.pricesList.find((el) => el.id == this.newAdd.pricesId);
      const minutes = Number(price.minutes) + Number(price.stoptime);
      this.newAdd.end = moment(this.newAdd.start)
        .add(minutes, "minutes")
        .format();

      const massage = this.massageList.find(
        (i) => i.id == this.newAdd.massageId
      );

      if (this.checkIfTimeAvailableEnd(this.newAdd.end, this.newAdd.adminsId)) {
        return;
      }

      this.calendarOptions.events.push({
        id: null,
        title: new Date(this.newAdd.start),
        start: new Date(this.newAdd.start),
        end: new Date(this.newAdd.end),
        resourceId: Number(this.newAdd.adminsId),
        allDay: false,
        backgroundColor: massage.color,
      });

      this.bodyArray.push(this.newAdd);
      this.modalShow = false;

      this.newAdd = {
        start: null,
        end: null,
        adminsId: null,
        massageId: null,
        pricesId: null,
      };
    },
    save() {
      if (this.body.paymentMethod) {
        const array = [];

        const user = JSON.parse(localStorage.adminKB);

        for (const item of this.bodyArray) {
          array.push({
            ...item, ...this.body, fromWhere: "adminEdit",
            fromWhereTime:
              moment().format("YYYY-MM-DD HH:mm"),
            fromWhereUser: user.id


          });
        }

        this.$http.put(`appointments/${this.id}`, array[0]).then(
          (res) => {
            this.$toast.add({
              severity: "success",
              summary: "Done Successfully",
              detail: "Added Successfully",
              life: 3000,
            });

            // if (this.body.paymentMethod == "cash") {
            //   // this.submit();

            this.$socket.emit("send", {});
            this.$router.go(-1);
            // } else {
            //   const total = 50;
            //   this.$router.push("/Payment/" + total);
            // }
          },
          (err) => {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Fields must be filled out",
          life: 3000,
        });
      }
    },
    $t(val) {
      return val;
    },
    async onTime() {
      const { data } = await this.$http.get(
        `appointments?isC=0&date=${this.day}`
      );
      const { stopes } = await this.$http.get(`stoptimes?date=${this.day}`);
      if (
        (data &&
          data.data &&
          this.appointmentsList.length != data.data.length) ||
        (stopes && stopes.data && stopes.data.length != this.stopList.length)
      ) {
        this.getData();
      }
    },
    async loadFullCalendar() {
      if (!this.isFullCalendarLoaded) {
        this.fullCalendarComponent = () => import('@fullcalendar/vue');
        this.isFullCalendarLoaded = true;
      }
    },
  },
  mounted() {
    this.$socket.on("resved", (data) => {
      this.onTime().then(() => { });
    });
  },
  created() {

    this.loadFullCalendar();
    const id = this.$route.params.id;

    this.id = id;
    this.$http.get(`admins`).then((res) => {
      this.userList = res.data.data;
      this.day = moment().format("YYYY-MM-DD");
    });

    this.$http.get(`appointments/${id}`).then((res) => {
      const data = res.data.data;

      this.body.email = data.email;
      this.body.phone = data.phone;
      this.body.sexType = data.sexType;
      this.body.fname = data.fname;
      this.body.lname = data.lname;
      this.body.address = data.address;
      this.body.zip = data.zip;
      this.body.city = data.city;
      this.body.country = data.country;
    });
    // this.$http.post(`users/getProfile`, {}).then((res) => {
    //   const user = res.data.data;

    //   this.body.usersId = user.id;
    //   this.body.email = user.email;
    //   this.body.phone = user.phone;
    //   this.body.sexType = user.sexType;
    //   this.body.fname = user.fname;
    //   this.body.lname = user.lname;
    //   this.body.address = user.address;
    //   this.body.zip = user.zip;
    //   this.body.city = user.city;
    //   this.body.country = user.country;
    // });
  },
  watch: {
    day(val) {
      if (val) {
        this.getData();
      }
    },

    "newAdd.massageId"(val) {
      if (val) {
        const one = this.massageList.find((el) => Number(el.id) == Number(val));
        if (one) {
          this.pricesList = one.pricesList;
          this.newAdd.pricesId = this.pricesList[0].id;
        }
      }
    },
  },
  head: {
    title: {
      inner: "Appointment",
    },
    // Meta tags
    meta: [
      { name: "application-name", content: "Kleopatra Massage & Beauty" },
      {
        name: "description",
        content: "Kleopatra Massage & Beauty",
      }, // id to replace intead of create element
      // ...
      // Twitter
      { name: "twitter:title", content: "Kleopatra Massage & Beauty" },
      // with shorthand
      {
        n: "twitter:description",
        c: "Kleopatra Massage & Beauty",
      },
      // ...
      // Google+ / Schema.org
      { itemprop: "name", content: "Kleopatra Massage & Beauty" },
      { itemprop: "description", content: "Kleopatra Massage & Beauty" },
      // ...
      // Facebook / Open Graph
      // { property: 'fb:app_id', content: '123456789' },
      { property: "og:title", content: "Kleopatra Massage & Beauty" },
      // with shorthand
      {
        p: "og:image",
        c: "https://kleopatramassage-beauty.com/image/logo.png",
      },
      // ...
    ],
  },
};
</script>

<style>
@media (max-width: 767.98px) {
  .fc .fc-view-harness {
    height: 856.296px !important;
  }
}

.fc .fc-scrollgrid-section table {
  width: 100% !important;
  /* height: 825px !important; */
}

.fc-scrollgrid-section-body .fc-scrollgrid-section table {
  width: 100% !important;
  /* height: 856.296px !important; */
}

.asa {
  font-weight: 700;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.5);
}
</style>
